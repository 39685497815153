import React from 'react'
import logo from './app/assets/images/logo.png'
import Button from './Button'

function App() {
  const [hours, setHours] = React.useState<string>(`00`)
  const [minutes, setMinutes] = React.useState<string>(`00`)
  const [seconds, setSeconds] = React.useState<string>(`00`)
  

  return (
    <div className="container grid justify-items-center content-start gap-2 bg-gray-800 h-screen p-2">
      <img src={logo} className=" max-w-xs mx-auto w-full" alt="The Logan and Cody Show"/>
      <h1 className=" text-2xl text-gray-100 mb-2">Season 2 is coming...</h1>
      <div className=" text-5xl sm:text-6xl text-red-600 font-semibold w-full text-center grid grid-flow-col gap-1 sm:gap-2 justify-center">
        <div className="justify-self-end w-20 sm:w-28 text-right">{hours}</div>
        <div>:</div>
        <div className="w-14 sm:w-16">{minutes}</div>
        <div>:</div>
        <div className="justify-self-start w-14 sm:w-16 text-left">{seconds}</div>
      </div>
      <div className="p-4 grid gap-4 grid-cols-2 text-gray-100 justify-self-center">
        <Button
          link="https://www.youtube.com/channel/UC-01whHhbDjV9YTcn3tYQZQ"
          text="Listen/Watch The Show!"
        ></Button>
        <Button
          link="https://discord.gg/yzrUVPTDD3"
          text="Join our Discord!"
        ></Button>
      </div>
    </div>
  )
}

export default App
