interface Props {
    link: string
    text: string
}

function Button({
    link,
    text,
}: Props) {
    return (
        <a className="bg-red-700 py-2 px-4 rounded text-center" href={link} target="_blank" rel="noreferrer">{text}</a>
    )
}

export default Button